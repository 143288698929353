<script>
import {getWs} from '@/ws.service.js'
import download from 'downloadjs'

export default {
  data() {
    return {
      salesPersonClients: [],
      filteredClients: [],
      searchName: '',
      monthLabels: [],
      salePersonUsername: '' // Assuming this is the salesperson username
    }
  },
  mounted() {
    this.loadInfos()
    this.setMonthLabels()
  },
  methods: {
    loadInfos() {
      const salePersonUsername = this.salePersonUsername

      getWs(
        '/bo/commercial/salesperson/clients-last-months-transactions-by-salesperson',
        true,
        {
          salePersonUsername: salePersonUsername
        },
        response => {
          this.salesPersonClients = response.data
          this.filteredClients = response.data
          console.log(response.data)
        },
        error => {
          console.error(error)
        }
      )
    },
    setMonthLabels() {
      const monthNames = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
      const currentMonth = new Date().getMonth()
      this.monthLabels = []
      for (let i = 0; i < 7; i++) {
        const monthIndex = (currentMonth - i + 12) % 12
        this.monthLabels.push(monthNames[monthIndex])
      }
    },
    filterByName() {
      if (this.searchName === '') {
        this.filteredClients = this.salesPersonClients
      } else {
        this.filteredClients = this.salesPersonClients.filter(client =>
          client.name.toLowerCase().includes(this.searchName.toLowerCase())
        )
      }
    },
    searchByUsername() {
      getWs(
        '/bo/commercial/salesperson/clients-last-months-transactions-by-salesperson',
        true,
        {
          salePersonUsername: this.salePersonUsername,
          clientUsername: this.searchName // Ensure this is passed correctly
        },
        response => {
          this.filteredClients = response.data
        },
        error => {
          console.error(error)
        }
      )
    },
    generateCsv() {
      const headers = [
        'Username',
        'Nome',
        'Email',
        'Phone 1',
        ...this.monthLabels
      ];
      const rows = this.filteredClients.map(client => [
        client.username,
        client.name,
        client.email,
        client.phone1,
        client.mes7 || '',
        client.mes6 || '',
        client.mes5 || '',
        client.mes4 || '',
        client.mes3 || '',
        client.mes2 || '',
        client.mes1 || ''
      ]);

      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');

      download('data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent), 'clients.csv', 'text/csv');
    }
  },
  watch: {
    searchName() {
      this.filterByName()
    }
  }
}
</script>

<template>
  <div class="dzs-acc-search">
    <div class="row">
      <div class="col-md-12 card">
        <div class="card-header">
          <h4>Movimentação nos últimos 6 meses</h4>
        </div>
        <div class="row">
          <div class="col-md-12 row">
            <div class="row is-align-bottom col-md-10">
              <div class="col-md-6">
                <label class="control-label">Buscar por Usuário</label>
                <fg-input
                  class="input-group two-fifths-width"
                  v-model="searchName"
                />
              </div>
              <div class="ml-1 col-md-4" style="margin-top: 12px">
                <p-button type="success" @click.prevent="searchByUsername">
                  <i slot="label" class="nc-icon nc-check-2"></i>
                  Buscar
                </p-button>
              </div>
            </div>


            <div class="ml-1" style="margin-top: 12px">
              <p-button type="success" @click.prevent="generateCsv">
                <i slot="label" class="nc-icon nc-cloud-download-93"></i>
                Baixar CSV
              </p-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-2">
            <el-table :data="filteredClients" class="centered-table">
              <el-table-column
                prop="username"
                label="Usuário"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="Nome"
                class-name="centered-column name-column"
              ></el-table-column>
              <el-table-column
                prop="phone1"
                label="Telefone"
                class-name="centered-column phone-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[0]"
                prop="mes7"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[1]"
                prop="mes6"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[2]"
                prop="mes5"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[3]"
                prop="mes4"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[4]"
                prop="mes3"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[5]"
                prop="mes2"
                class-name="centered-column"
              ></el-table-column>
              <el-table-column
                :label="monthLabels[6]"
                prop="mes1"
                class-name="centered-column"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
